<template>
  <div>
    <b-modal centered v-if="packageInfo" id="packageDetails" title="Details" hide-footer no-close-on-backdrop>
      <div class="about-info m-0 p-0">
        <b-row>
          <b-col cols="4" class="text-primary">Title: </b-col>
          <b-col cols="8" class="text-black">{{packageInfo.title}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="4" class="text-primary">Count: </b-col>
          <b-col cols="8" class="text-black">{{packageInfo.count}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="4" class="text-primary">Invitation:</b-col>
          <b-col cols="8" class="text-black">{{packageInfo.invitation}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="4" class="text-primary">Freezing:</b-col>
          <b-col cols="8" class="text-black">{{packageInfo.freezing}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="4" class="text-primary">Auto Start After:</b-col>
          <b-col cols="8" class="text-black">{{packageInfo.auto_start_after}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="4" class="text-primary">Services: </b-col>
          <b-col cols="8" class="text-black">
            <ul style="margin: 0 !important; padding: 0 !important;">
              <li v-for="(i, key) in packageInfo.service" :key="key" class="d-flex justify-content-between align-items-center mb-2">
                {{i.title }}
                <b-badge variant="primary" pill>{{i.pivot.count}}</b-badge>
              </li>
            </ul>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <!-- Start Filter -->
    <div fluid class="filter">
      <div class="iq-card p-1">
        <div class="row">
          <div class="col-md-12 my-1">
            <date-range-picker
                class="w-100"
                ref="picker"
                opens=false
                :timePicker=false
                :timePicker24Hour=false
                :showWeekNumbers=true
                :showDropdowns=true
                format='mm/dd/yyyy'
                direction="center"
                :autoApply=true
                v-model="dateRange"
                :linkedCalendars=true
                customRangeLabel= 'Custom Range'
                @update="fillTable"
            >
            </date-range-picker>
          </div>
          <div class="col-md-3 my-1">
            <input type="text" placeholder="search by package name" class="form-control" v-model="filter.title"
                   @input="fillTable" >
          </div>
          <div class="col-md-3 my-1">
            <select class="form-control"  v-model="filter.project" @change = 'fillTable'>
              <option selected disabled value="">select Project...</option>
              <option v-for="(i, key) in getUserProjects" :key="key" :value="i.id">
                <span>{{i.en_title}}</span>
              </option>
            </select>
          </div>
          <div class="col-md-3 my-1">
            <select class="form-control"  v-model="filter.status" @change = 'fillTable'>
              <option selected disabled value="">select Status...</option>
              <option value="active">
                <span>Active</span>
              </option>
              <option value="disactive">
                <span>Disactive</span>
              </option>
            </select>
          </div>
          <div class="col-md-3 my-1">
            <select class="form-control"  v-model="filter.type" @change = 'fillTable'>
              <option selected disabled value="">select Type...</option>
              <option value="">
                <span>All</span>
              </option>
              <option value="package">
                <span>Package</span>
              </option>
              <option value="service">
                <span>Service</span>
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <!-- End Filter -->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Packages</h4>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-table v-else sort-icon-left primary-key="name" striped responsive :fields="columns" :items="allPackages" class="mb-0 table-borderless "  id="table-transition-example" :tbody-transition-props="transProps">
              <template v-slot:cell(details)="data">
                <b-button variant=" iq-bg-primary" size="sm" @click="showInfo(data.item.id)">
                  <i class="las la-money-bill"></i></b-button>
              </template>
              <template v-slot:cell(active_purchases)="data">
                <b-button variant=" iq-bg-success" size="sm" @click="showInfoPurchases(data.item.id, 'active')">
                {{data.item.purchases}}
                </b-button>
              </template>
              <template v-slot:cell(expire_purchases)="data">
                <b-button variant=" iq-bg-danger" size="sm" @click="showInfoPurchases(data.item.id, 'expired')">
                {{data.item.expire_purchases}}
                </b-button>
              </template>
            </b-table>
            <b-pagination v-model="currentPage"
                          class="mt-3"
                          :total-rows="total"
                          align="right"
                          :per-page="per_page"
                          aria-controls="my-table"
                          @input="fillTable"
            ></b-pagination>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import { mapGetters } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import reportService from '../../../reports/services/reports'
export default {
  name: 'allPackages',
  data () {
    return {
      packageInfo: '',
      showPhone: '',
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      filter: {
        project: '',
        title: '',
        status: '',
        type: ''
      },
      dateRange: {
        startDate: '',
        endDate: ''
      },
      currentPage: 1,
      per_page: 10,
      total: 6,
      loadTable: true,
      columns: [
        { label: 'Title', key: 'title', class: 'text-left', sortable: true },
        { label: 'Period', key: 'period', class: 'text-left', sortable: true },
        { label: 'Purchases', key: 'purchases', class: 'text-left', sortable: true },
        { label: 'Active Purchases', key: 'active_purchases', class: 'text-left', sortable: true },
        { label: 'Expire Purchases', key: 'expire_purchases', class: 'text-left', sortable: true },
        { label: 'Type', key: 'type', class: 'text-left', sortable: true },
        { label: 'Default Service', key: 'default_service', class: 'text-left', sortable: true },
        { label: 'Remaining', key: 'remaining', class: 'text-left', sortable: true },
        { label: 'Show Details', key: 'details', class: 'text-left' }
      ],
      allPackages: []
    }
  },
  computed: {
    ...mapGetters(['getUserProjects'])
  },
  methods: {
    resetFilter () {
      this.filter = {
        project: '',
        title: '',
        status: '',
        type: ''
      }
      this.filterTable()
    },
    showInfoPurchases (packageId, status) {
      this.$router.push({
        name: 'allPurchases',
        query: {
          ...this.filter,
          project_id: this.filter.project,
          date_from: this.dateRange.startDate ? new Date(this.dateRange.startDate).toJSON().slice(0, 10) : '',
          date_to: this.dateRange.endDate ? new Date(this.dateRange.endDate).toJSON().slice(0, 10) : '',
          status: status
        },
        params: { id: packageId }
      })
    },
    fillTable () {
      this.loadTable = true
      reportService.getPackageReports({
        pageNumber: this.currentPage,
        title: this.filter.title,
        project: this.filter.project,
        status: this.filter.status,
        type: this.filter.type,
        dateFrom: this.dateRange.startDate ? new Date(this.dateRange.startDate).toJSON().slice(0, 10) : '',
        dateTo: this.dateRange.endDate ? new Date(this.dateRange.endDate).toJSON().slice(0, 10) : ''
      }).then(res => {
        this.allPackages = res.data.data
        this.currentPage = res.data.meta.current_page
        this.per_page = res.data.meta.per_page
        this.total = res.data.meta.total
        this.loadTable = false
      })
    },
    showInfo (id) {
      this.$store.dispatch('getSpacificPackages', id).then(data => {
        // console.log(data.data.data)
        this.packageInfo = data.data.data
        this.$bvModal.show('packageDetails')
      })
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DateRangePicker
  },
  mounted () {
    core.index()
  },
  created () {
    this.fillTable()
  }
}
</script>
<style>
.nav-link.router-link-exact-active.router-link-active{
  background: var(--iq-primary) !important;
  color: #fff !important;
}
.colorSpan{
  width: 20px;
  height: 20px;
}
[dir=ltr][mode=light] .nav-tabs {
  background: #fff !important;
  border-radius: 10px !important;

  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
th{
  white-space: pre !important;
  vertical-align: middle !important;
}
td{
  white-space: pre !important;
  vertical-align: middle !important;
}
</style>
